import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import {LiquidDistortionText} from "react-text-fun";
import {useWindowSize} from "@react-hook/window-size";
import './links.css';
import {graphql, useStaticQuery} from "gatsby";

export default function Links(props) {
  const [timer, setTimer] = useState(null);
  const [width, height] = useWindowSize(
    360 /* initialWidth when there is no window */,
    720 /* initialHeight when there is no window */,
    {wait: 100}
  );

  useEffect( () => {
    document.body.style.cursor = "auto";

    const mouseClickHandler = () => {
      document.body.style.backgroundImage = "repeating-linear-gradient(180deg, #1126F8, #1126F8 5px, #F1A896 5px, #F1A896 10px, #44D3F2 10px, #44D3F2 15px)";

      if (timer !== null ) {
        clearTimeout(timer);
      }
      const newTimer = setTimeout( () => {
        document.body.style.backgroundImage = null;
      }, 300);
      setTimer(newTimer)
    };
    document.addEventListener("mousedown", mouseClickHandler);
    return () => {
      document.removeEventListener("mousedown", mouseClickHandler);
    };
  },[]);


  const sendSlackSpying = (l) => {
    fetch(`/.netlify/functions/spying?link=${l}`, {
      method: 'post',
      headers: new Headers({ "Content-Type": "application/json" })
    }).then( (response) => {
      return response.json();
    }).then( (data) => {
      console.log(data)
    });
  };
  //
  // const OpenLink = () => {
  //   if (typeof window !== 'undefined' && window) {
  //     window.open('https://www.seetickets.us/event/Balkans-Reunion-in-NYC-w-Patio-Hypolyxo-Navy-Gangs/406646?ttc=1031537&fbclid=IwAR3nks6lJjXKVc8fN2kBTGfC-Lq1paG8LotqAu-_S4KtTyz74myFVdQwFE0', '_blank');
  //   }
  //   sendSlackSpying("NYC 09/26 2020 tickets")
  // };

  const OpenLink = () => {
    if (typeof window !== 'undefined' && window) {
      window.open('https://balkans.bandcamp.com/album/balkans', '_blank');
    }
    sendSlackSpying("BALKANS deluxe reissue")
  };

  const OpenLink2 = () => {
    if (typeof window !== 'undefined' && window) {
      window.open('https://www.seetickets.us/event/Balkans/478683', '_blank');
    }
    sendSlackSpying("NYC 05/26 2022 tickets")
  };

  const OpenLink3 = () => {
    if (typeof window !== 'undefined' && window) {
      window.open('https://www.eventbrite.com/e/balkans-reunion-show-w-sword-ii-blammo-irrelevant-music-djs-tickets-314581721397', '_blank');
    }
    sendSlackSpying("ATL 06/03 2022 tickets")
  };

  const OpenLink4 = () => {
    if (typeof window !== 'undefined' && window) {
      window.open('https://open.spotify.com/artist/6ozbYaDYh9AFYciPvk6iBO?si=of-l9RdUQJm6FkEppTcqiA', '_blank');
    }
    sendSlackSpying("Someone wants to listen to Spotify")
  };

  return (
    <Layout
      poster={null}
    >
      <div className={'balkans-link-wrapper'}>
        <div className={'balkans-show balkans-show-link'} role="link" style={{ display: `flex`, justifyContent: `center`, margin: '0 0 4rem 0' }} onClick={() => OpenLink2()}>
          <LiquidDistortionText
            text={width > 1000 ? "BALKANS Spotify" : "Spotify"}
            fontFamily={'AppleChancery'}
            fill={'black'}
            fontSize={width > 1000 ? 48 : 38 }
            speed={.15}
            volatility={0.02}
            id={'spotify'}
            // rotation={45.0}
            // distortX={1.5}
            // distortY={1.5}
            // noiseAmplitude={0.5}
            // noiseVolatility={.5}
            // cellWidth={.005}
            // cellRadius={1}
            // dodge={false}
            // dodgeY={0.1}
            // dodgeSpread={0.08}
          />
        </div>
        <div className={'balkans-show balkans-show-link'} role={"link"} style={{ display: `flex`, justifyContent: `center`, margin: '1rem 0 6rem 0' }} onClick={() => OpenLink()}>
          <LiquidDistortionText
            text={width > 1000 ? "BALKANS vinyl deluxe reissue" : "Deluxe reissue"}
            fontFamily={'AppleChancery'}
            fill={'black'}
            fontSize={width > 1000 ? 48 : 38 }
            speed={.15}
            volatility={0.02}
            // rotation={45.0}
            // distortX={1.5}
            // distortY={1.5}
            // noiseAmplitude={0.5}
            // noiseVolatility={.5}
            // cellWidth={.005}
            // cellRadius={1}
            // dodge={false}
            // dodgeY={0.1}
            // dodgeSpread={0.08}
          />
        </div>
        <div className={'balkans-show balkans-show-link'} role="link" style={{ display: `flex`, justifyContent: `center`, margin: '0 0 4rem 0' }} onClick={() => OpenLink2()}>
          <LiquidDistortionText
              text={width > 1000 ? "NYC 05/26/2022 tickets" : "NYC tickets"}
              fontFamily={'AppleChancery'}
              fill={'black'}
              fontSize={width > 1000 ? 48 : 38 }
              speed={.15}
              volatility={0.02}
              id={'nyc'}
              // rotation={45.0}
              // distortX={1.5}
              // distortY={1.5}
              // noiseAmplitude={0.5}
              // noiseVolatility={.5}
              // cellWidth={.005}
              // cellRadius={1}
              // dodge={false}
              // dodgeY={0.1}
              // dodgeSpread={0.08}
          />
        </div>
        <div className={'balkans-show balkans-show-link'} role="link" style={{ display: `flex`, justifyContent: `center`, margin: '1rem 0 4rem 0' }} onClick={() => OpenLink3()}>
          <LiquidDistortionText
            text={width > 1000 ? "ATL 06/03/2022 tickets" : "ATL tickets"}
            fontFamily={'AppleChancery'}
            fill={'black'}
            fontSize={width > 1000 ? 48 : 38 }
            speed={.15}
            volatility={0.02}
            id={'atl'}
            // rotation={45.0}
            // distortX={1.5}
            // distortY={1.5}
            // noiseAmplitude={0.5}
            // noiseVolatility={.5}
            // cellWidth={.005}
            // cellRadius={1}
            // dodge={false}
            // dodgeY={0.1}
            // dodgeSpread={0.08}
          />
        </div>
      </div>
    </Layout>
  )
}
